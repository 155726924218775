(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BuyerPanelController', BuyerPanelController);

    BuyerPanelController.$inject = ['$state', 'GiroConstants', 'ProgramBuyer', 'CreditsAvailabilityReport'];

    function BuyerPanelController($state, GiroConstants, ProgramBuyer, CreditsAvailabilityReport) {
        var vm = this;
        vm.anticipatedBalance = {};
        vm.providersBalance = [];
        vm.report = [];
        vm.currency = GiroConstants.Constants.currency;
        vm.reportButton = false;
        vm.creditsReport = [];

        ProgramBuyer.getAnticipatedBalance(function (result) {
            vm.anticipatedBalance = result;
        });

        ProgramBuyer.getProviderBalance(function (result) {
            vm.providersBalance = result;
        });

        ProgramBuyer.getProviderBalanceReport(function (result) {
            vm.report = result.titles;
            vm.reportButton = true;
        });

        vm.creditsReport = function() {
            CreditsAvailabilityReport.getReport().$promise.then(function(result) {
                const blob = new Blob([result.data], { type: 'application/octet-stream' });
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;

                a.download = result.fileName.split("=")[1] || 'relatorio.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(downloadUrl);
            });
        };
    }
})();
